var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/dashboard","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-architecture-and-city"}),_c('span',{staticClass:"menu-text"},[_vm._v("Home")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.sprawdzTajnyDostep('clients')),expression:"sprawdzTajnyDostep('clients')"}],attrs:{"to":"/customer","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon far fa-user"}),_c('span',{staticClass:"menu-text"},[_vm._v("Baza klientów")])])])]}}])}),_c('router-link',{attrs:{"to":"/product","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-expand"}),_c('span',{staticClass:"menu-text"},[_vm._v("Produkty")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.sprawdzTajnyDostep('orders')),expression:"sprawdzTajnyDostep('orders')"}],attrs:{"to":"/orders","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"text-center menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"text-light"},[_vm._v(_vm._s(_vm.getNewOrdersCount))]),_c('i',{staticClass:"menu-icon fas fa-dollar-sign"}),_c('span',{staticClass:"menu-text"},[_vm._v("Zamówienia")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.sprawdzTajnyDostep('orders')),expression:"sprawdzTajnyDostep('orders')"}],attrs:{"to":"/orderPanel","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-solar-panel"}),_c('span',{staticClass:"menu-text"},[_vm._v("Panel zamówień")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.sprawdzTajnyDostep('lab')),expression:"sprawdzTajnyDostep('lab')"}],attrs:{"to":"/orderPanelLab","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-flask"}),_c('span',{staticClass:"menu-text"},[_vm._v("Panel laboratorium")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.sprawdzTajnyDostep('lab')),expression:"sprawdzTajnyDostep('lab')"}],attrs:{"to":"/labl/labOrders","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-microscope"}),_c('span',{staticClass:"menu-text"},[_vm._v("Zlecenia laboratoryjne")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.sprawdzTajnyDostep('acceptance')),expression:"sprawdzTajnyDostep('acceptance')"}],attrs:{"to":"/lab/results/waiting","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-clock"}),_c('span',{staticClass:"menu-text"},[_vm._v("Wyniki laboratoryjne do zatwierdzenia")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.sprawdzTajnyDostep('lab')),expression:"sprawdzTajnyDostep('lab')"}],attrs:{"to":"/lab/control","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-briefcase"}),_c('span',{staticClass:"menu-text"},[_vm._v("Kontrola laboratorium")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.sprawdzTajnyDostep('lab')),expression:"sprawdzTajnyDostep('lab')"}],attrs:{"to":"/lab/researchLine","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-book"}),_c('span',{staticClass:"menu-text"},[_vm._v("Kierunki badań - ustawienia")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.sprawdzTajnyDostep('lab')),expression:"sprawdzTajnyDostep('lab')"}],attrs:{"to":"/lab/drug-card","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-pills"}),_c('span',{staticClass:"menu-text"},[_vm._v("Przyjęcie leków")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.sprawdzTajnyDostep('lab')),expression:"sprawdzTajnyDostep('lab')"}],attrs:{"to":"/soilCards","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-mortar-pestle"}),_c('span',{staticClass:"menu-text"},[_vm._v("Panel pożywek")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.sprawdzTajnyDostep('users')),expression:"sprawdzTajnyDostep('users')"}],attrs:{"to":"/myCompany","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        // isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-flag"}),_c('span',{staticClass:"menu-text"},[_vm._v("Urlopy")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.sprawdzTajnyDostep('analysis')),expression:"sprawdzTajnyDostep('analysis')"}],attrs:{"to":"/myCompany/analysis","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        // isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-chart-bar"}),_c('span',{staticClass:"menu-text"},[_vm._v("Analiza sprzedaży")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.sprawdzTajnyDostep('users')),expression:"sprawdzTajnyDostep('users')"}],attrs:{"to":"/myCompany/users","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        // isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-users"}),_c('span',{staticClass:"menu-text"},[_vm._v("Użytkownicy")])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }