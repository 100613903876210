<template>
  <ul class="menu-nav">
    <router-link
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Home</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/customer"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
      v-show="sprawdzTajnyDostep('clients')"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon far fa-user"></i>
          <span class="menu-text">Baza klientów</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/product"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-expand"></i>
          <span class="menu-text">Produkty</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/orders"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
      v-show="sprawdzTajnyDostep('orders')"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="text-center menu-link" @click="navigate">
          <span class="text-light">{{ getNewOrdersCount }}</span
          ><i class="menu-icon fas fa-dollar-sign"></i>
          <span class="menu-text">Zamówienia</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/orderPanel"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
      v-show="sprawdzTajnyDostep('orders')"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-solar-panel"></i>
          <span class="menu-text">Panel zamówień</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/orderPanelLab"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
      v-show="sprawdzTajnyDostep('lab')"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-flask"></i>
          <span class="menu-text">Panel laboratorium</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/labl/labOrders"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
      v-show="sprawdzTajnyDostep('lab')"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-microscope"></i>
          <span class="menu-text">Zlecenia laboratoryjne</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/lab/results/waiting"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
      v-show="sprawdzTajnyDostep('acceptance')"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-clock"></i>
          <span class="menu-text">Wyniki laboratoryjne do zatwierdzenia</span>
        </a>
      </li>
    </router-link>

    <router-link
        to="/lab/control"
        v-slot="{ href, navigate, isActive, isExactActive }"
        custom
        v-show="sprawdzTajnyDostep('lab')"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-briefcase"></i>
          <span class="menu-text">Kontrola laboratorium</span>
        </a>
      </li>
    </router-link>

    <router-link
        to="/lab/researchLine"
        v-slot="{ href, navigate, isActive, isExactActive }"
        custom
        v-show="sprawdzTajnyDostep('lab')"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-book"></i>
          <span class="menu-text">Kierunki badań - ustawienia</span>
        </a>
      </li>
    </router-link>

    <router-link
        to="/lab/drug-card"
        v-slot="{ href, navigate, isActive, isExactActive }"
        custom
        v-show="sprawdzTajnyDostep('lab')"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-pills"></i>
          <span class="menu-text">Przyjęcie leków</span>
        </a>
      </li>
    </router-link>

    <!-- <router-link
        to="/lab/suplement"
        v-slot="{ href, navigate, isActive, isExactActive }"
        custom
        v-show="sprawdzTajnyDostep('lab')"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-pills"></i>
          <span class="menu-text">Suplementy</span>
        </a>
      </li>
    </router-link> -->

    <router-link
      to="/soilCards"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
      v-show="sprawdzTajnyDostep('lab')"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon fas fa-cauldron"></i> -->
          <i class="menu-icon fas fa-mortar-pestle"></i>
          <span class="menu-text">Panel pożywek</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/myCompany"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
      v-show="sprawdzTajnyDostep('users')"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          // isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-flag"></i>
          <span class="menu-text">Urlopy</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/myCompany/analysis"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
      v-show="sprawdzTajnyDostep('analysis')"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          // isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-chart-bar"></i>
          <span class="menu-text">Analiza sprzedaży</span>
        </a>
      </li>
    </router-link>

    <router-link
        to="/myCompany/users"
        v-slot="{ href, navigate, isActive, isExactActive }"
        custom
        v-show="sprawdzTajnyDostep('users')"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          // isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-users"></i>
          <span class="menu-text">Użytkownicy</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "KTMenu",
  mounted() {},
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    sprawdzTajnyDostep(role) {
      return this.currentUserPersonalInfo.roles.includes(role);
      // return (
      //     this.currentUserPersonalInfo.email === "tomasz.suchanek@ladrob.pl" ||
      //     this.currentUserPersonalInfo.email === "lukasz.latala@ladrob.pl" ||
      //     this.currentUserPersonalInfo.email === "anna@ladrob.pl" ||
      //     this.currentUserPersonalInfo.email === "iga@ladrob.pl" ||
      //     this.currentUserPersonalInfo.email === "roksana@ladrob.pl" ||
      //     this.currentUserPersonalInfo.email === "dev" ||
      //     this.currentUserPersonalInfo.email === "developer" ||
      //     this.currentUserPersonalInfo.email === "ksuchan@getso.pl"
      // );
    }
  },
  computed: {
    ...mapGetters(["orders", "currentUserPersonalInfo", "currentUser"]),
    getNewOrdersCount() {
      return _.filter(this.orders, o => {
        return o.status === "new";
      }).length;
    }
  }
};
</script>
